<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'10'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>矿山管理</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>矿山列表</span>
				</div>
        <!--
				<div class="left-list-item" @click="leftClick('1')">
					<span>备件分类</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('2')">
					<span>备件数据</span>
				</div>-->
			</div>
			<div class="right-next">
				<div class="date-table">
					<div class="title">
						<span>备件数据列表</span>
					</div>
					<div class="operation-btn">
						<el-button type="primary" @click="addfile(mine_id,pid)">添加文件</el-button>
            <el-button type="primary" @click="addpackage(mine_id,pid)">添加文件夹</el-button>
            <el-button type="danger" @click="back()">返回</el-button>
						<!--<el-button type="danger" @click="deleteInfo" class="ml-40" :disabled="is_disabled">删除选中数据</el-button>-->
            <!--<el-button type="danger" style="float:right;" @click="type(mine_id)">备件分类列表</el-button>-->
					</div>
					<div class="mt-20"></div>
					<el-table :data="tableData.data" border style="width: 100%"
						@selection-change="handleSelectionChange">
						<!--<el-table-column type="selection" width="55" align="center">
						</el-table-column>-->
						<el-table-column prop="id" label="ID" width="80" align="center">
						</el-table-column>
						<el-table-column prop="name" label="备件数据名称" width="180" align="center">
						</el-table-column>
						<el-table-column prop="mine_id" label="备件数据所属矿山" width="180" align="center">
						</el-table-column>
						<el-table-column prop="size" label="备件数据大小/MB" width="180" align="center">
						</el-table-column>
						<el-table-column prop="type_id" label="备件数据类型" width="180" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.type_id == 1"> 文件夹</span>
                <span v-if="scope.row.type_id == 0"> 文件</span>
              </template>
						</el-table-column>
						<el-table-column prop="file_type" label="备件文件类型" width="120" align="center">
						</el-table-column>
						<el-table-column prop="created_at" label="上传时间" width="180" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<!-- <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑
								</el-button> -->
                <el-button size="mini" type="primary" v-if="scope.row.type_id == 1" @click="handleIn(scope.$index, scope.row)">进入
								</el-button>
                <el-button size="mini" type="primary" v-if="scope.row.type_id == 1" @click="handleEdit(scope.$index, scope.row)">编辑
								</el-button>
                <el-button size="mini" type="danger" v-if="scope.row.type_id == 1" @click="handleDelete(scope.$index, scope.row)">删除
								</el-button>
								<el-button size="mini" type="danger" v-if="scope.row.type_id == 0" @click="handleDelete(scope.$index, scope.row)">删除
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="bottom-paging">
						<!-- tableData.data.order_total -->
						<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="page" :page-sizes="[5, 10, 20, 30, 100]" :page-size="num"
							layout="total, prev, pager, next, sizes" :total="tableData.total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../../components/leftList.vue";
	import topHeader from "../../../components/header.vue"
	export default {
		components: {
			leftList,
			topHeader
		},
		inject: ["reload"],
		data() {
			return {
				tableData: [],
				selectList: [],
				is_disabled:true,
				isCollapse: false,
				page: 1,
				num: 10,
        mine_id:this.$route.query.mine_id,
        pid:this.$route.query.pid
			};
		},
		created() {
			this.getList();
			// this.getCitylist();
			// this.getClass();
		},
		methods: {
			imgClick(url) {
				console.log(url);
				this.srcList[0] = url;
			},
			addfile(mine_id,pid){
        this.$router.push({
          query: {
						mine_id: mine_id,
            pid:pid,
					},
					name: 'minefileedit'
				});
			},
      back(){
        this.$router.go(-1);
      },
      addpackage(mine_id,pid){
        this.$router.push({
          query: {
						mine_id: mine_id,
            pid:pid,
					},
					name: 'minefilepackage'
				});
			},
      type(mine_id) {
				this.$router.push({
          query: {
						mine_id: mine_id
					},
					name: 'minetype'
				});
			},
			handleEdit(index, row) {
				console.log(row.mine_id);
				this.$router.push({
					query: {
            mine_id:row.mine_id,
            pid:row.pid,
            id:row.id,
					},
					name: 'minefilepackage'
				});
			},
			deleteInfo(){
				this.$confirm('此操作将永久删除选中数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$post('delmuch/mine_file', {
						ids: this.selectList
					}).then(res => {
						console.log(res);
						if (res.data.status == 200) {
							this.$notify({
								title: '提示',
								message: '操作成功！',
								type: 'success'
							});
							this.reload();
						} else {
							this.$notify.error({
								title: '提示',
								message: res.data.message
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
      handleIn(index, row) {
        this.$router.push({
					query: {
            mine_id:this.$route.query.mine_id,
            pid: row.id,

					},
					name: 'minefile'
				});
        this.reload();
      },
			handleDelete(index, row) {
				console.log(index, row);
				this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$post('del/mine_file', {
						id: row.id
					}).then(res => {
						console.log(res);
						if (res.data.status == 200) {
							this.$notify({
								title: '提示',
								message: '操作成功！',
								type: 'success'
							});
							this.reload();
						} else {
							this.$notify.error({
								title: '提示',
								message: res.data.message
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			handleSelectionChange(e) {
				this.selectList = [];
				for(let i = 0; i<e.length; i++){
					this.selectList.push(e[i].id);
				}
				console.log(this.selectList);
				if(this.selectList.length > 0){
					this.is_disabled = false;
				}else{
					this.is_disabled = true;
				}
			},
			getClass() {
				this.$get('get/category', {
					page: 1,
					num: 999
				}).then(res => {
					this.classList = res.data.data.data;
				})
			},
			getProvincelist() {
				this.$get('get/city', {
					province_id: this.province
				}).then(res => {
					this.cityList = res.data.data;
				});
			},
			provinceChange() {
				this.city = "";
				this.getProvincelist();
			},
			getCitylist() {
				this.$get('get/province', {}).then(res => {
					this.provinceList = res.data.data;
				});
			},
			getList() {
				this.$get('mine_file/get_list', {
					page: this.page,
					num: this.num,
          mine_id:this.$route.query.mine_id,
          pid:this.$route.query.pid,
				}).then(res => {
					console.log(res);
					// for(let i = 0; i < res.data.data.data.length; i++){
          //   if(res.data.data.data[i].type_id == 0){
          //     let fileName = res.data.data.data[i].url.lastIndexOf(".");//取到文件名开始到最后一个点的长度
          //     let fileNameLength = res.data.data.data[i].url.length;//取到文件名长度
          //     let fileFormat = res.data.data.data[i].url.substring(fileName + 1, fileNameLength);//截
          //     res.data.data.data[i].product_type = fileFormat;
          //
          //   }
          //
          //
					// }
					this.tableData = res.data.data;
				})
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
        this.num = val;
				this.getList();
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
        this.page = val;
				this.getList();
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/mine" ) {
					this.$router.push("/mine");
				} else if (index == 1 && this.$route.path != "/minetype") {
					this.$router.push("/minetype");
				} else if (index == 2 && this.$route.path != "/minefile") {
					this.$router.push("/minefile");
				} else {
					this.reload();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.date-table {
			margin-bottom: 20px;

			.operation-btn {
				margin-top: 30px;

				.el-button {
					padding: 12px 25px;
				}
			}

			.cityNumber {
				margin-top: 30px;
				padding-left: 140px;
			}

			.date-search {
				margin-top: 30px;

				.left,
				.right {
					display: inline-block;
				}

				.right {
					width: 900px;
					vertical-align: top;
				}

				.el-button {
					margin-left: 56px;
					padding: 12px 85px;
				}
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
